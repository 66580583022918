<template>
  <ValidationObserver :key=0 ref="user">
    <form class="ui form form-horizontal" @submit.prevent="validateForm" autocomplete="off">
      <ValidationProvider name="first_name" v-slot="{errors}" :rules="{ required: true, min: 2 }" slim>
        <div class="form-group" :class="{ 'has-error': errors.length > 0 }">
          <label for="firstName" class="col-sm-2 control-label">First Name</label>
          <div class="col-sm-10 col-md-8">
            <div class="input-group width-100">
              <input class="form-control width-100" name="first_name" placeholder="First name" type="text" autocomplete="off"
                     v-model="mutableValue.first_name"
                     id="firstName">
            </div>
            <span v-show="errors.length > 0" class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider name="last_name" v-slot="{errors}" :rules="{ required: true, min: 2 }" slim>
        <div class="form-group" :class="{ 'has-error': errors.length > 0 }">
          <label for="lastName" class="col-sm-2 control-label">Last Name</label>
          <div class="col-sm-10 col-md-8">
            <div class="input-group width-100">
              <input class="form-control width-100" name="last_name" placeholder="Last Name" type="text" autocomplete="off"
                     v-model="mutableValue.last_name"
                     id="lastName">
            </div>
            <span v-show="errors.length > 0" class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider name="email" v-slot="{errors}" :rules="{ required: true, email: true }" slim>
        <div class="form-group" :class="{ 'has-error': errors.length > 0 }">
          <label for="email" class="col-sm-2 control-label">Email</label>
          <div class="col-sm-10 col-md-8">
            <div class="input-group width-100">
              <input class="form-control width-100" name="email" placeholder="Email" type="text" autocomplete="off"
                     v-model="mutableValue.email"
                     id="email">
            </div>
            <span v-show="errors.length > 0" class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider name="job_role" v-slot="{errors}" slim v-if="accountFields.job_role">
        <div class="form-group" :class="{ 'has-error': errors.length > 0 }">
          <label for="email" class="col-sm-2 control-label">Job Role</label>
          <div class="col-sm-10 col-md-8">
            <div class="input-group width-100">
              <input class="form-control width-100" name="job_role" placeholder="Job Role" type="text" autocomplete="off"
                     v-model="mutableValue.job_role"
                     id="job_role">
            </div>
            <span v-show="errors.length > 0" class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider name="weekly_digest_optin" v-slot="{errors}" slim v-if="accountFields.weekly_digest_optin">
        <div class="form-group" :class="{ 'has-error': errors.length > 0 }">
          <label for="weekly_digest_optin" class="col-sm-2 control-label">Receive Weekly Digest</label>
          <div class="col-sm-10 col-md-8">
            <div class="input-group">
              <div class="input-group-addon">
                <input type="checkbox" class="form-control__checkbox" v-model="mutableValue.weekly_digest_optin">
              </div>
              <div class="form-control"></div>
            </div>
            <span v-show="errors.length > 0" class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>

      <div class="form-group" v-if="mutableValue.permissions && accountFields.permissions">
        <label class="col-sm-2 control-label">Permissions</label>
        <div class="col-sm-10 col-md-8">

          <p class="permissions__title">Collection</p>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Key</th>
                <th class="text-center">Include drafts</th>
                <th class="text-center">Bypass Paywall</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(collection, i) in collections" :key="i">
                <td>{{collection.key}}</td>
                <td class="text-center"><input type="checkbox" :name="collection.key" data-type="collection" :checked="collection.include_drafts"
                  :data-key="collection.key" data-action="include_drafts" @change="handleChange($event)"></td>
                <td class="text-center"><input type="checkbox" :name="collection.key" data-type="collection" :checked="collection.bypass_paywall"
                  :data-key="collection.key" data-action="bypass_paywall" @change="handleChange($event)"></td>
              </tr>
            </tbody>
          </table>

          <p class="permissions__title">Publisher</p>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Key</th>
                <th class="text-center">Include drafts</th>
                <th class="text-center">Bypass Paywall</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(publisher, i) in publishers" :key="i">
                <td>{{publisher.key}}</td>
                <td class="text-center"><input type="checkbox" :name="publisher.key" data-type="publisher" :checked="publisher.include_drafts"
                  :data-key="publisher.key" data-action="include_drafts" @change="handleChange($event)"></td>
                <td class="text-center"><input type="checkbox" :name="publisher.key" data-type="publisher" :checked="publisher.bypass_paywall"
                  :data-key="publisher.key" data-action="bypass_paywall" @change="handleChange($event)"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

        <div class="form-group" :class="{ 'has-error': errors.length > 0 }" v-if="accountFields.password">
          <label for="password" class="col-sm-2 control-label">Change Password</label>
          <div class="col-sm-10 col-md-8">
            <div class="input-group">
              <span class="input-group-addon">
                <input type="checkbox" v-model="changePassword">
              </span>
              <ValidationProvider name="country" v-slot="{errors}" slim>
                <input class="form-control" name="password" placeholder="Password" type="password" autocomplete="off"
                       v-model="mutableValue.password" :disabled="!changePassword"
                       id="password">
                <span v-show="errors.length > 0" class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>

      <ValidationProvider name="country" v-slot="{errors}" slim>
        <div class="form-group" :class="{ 'has-error': errors.length > 0 }" v-if="accountFields.country">
          <label for="country" class="col-sm-2 control-label">Country</label>
          <div class="col-sm-10 col-md-8">
            <div class="input-group">
              <select id="country" v-model="mutableValue.country" class="form-control">
                <option v-for="country in countries" :value="country['id']" :key="country['id']">{{ country['name'] }}</option>
              </select>
            </div>
            <span v-show="errors.length > 0" class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>

      <div class="form-group">
        <label for="region" class="col-sm-2 control-label">Region</label>
        <div class="col-sm-10 col-md-8">
          <select v-if="regions.length > 0" class="form-control" v-model="mutableValue.region">
            <optgroup :label="group['group_name'] != '' ? group['group_name'] : 'Choose a region'"
                      v-for="(group, key) in regions" :key="key">
              <option v-for="(region, k) in group['values']" :value="region" :key="k">{{ region }}</option>
            </optgroup>
          </select>

          <input v-else type="text" class="form-control" name="region" id="region" @input="mutableValue.region = $event.target.value"
                 autocomplete="off" :value="mutableValue.region" data-vv-as="region">
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-offset-2 col-sm-10 col-md-8">
          <loading-button>Submit</loading-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import LoadingButton from '@/components/utils/LoadingButton';
import FormMixin from '@/mixins/FormMixin';
import UserMixin from '@/mixins/UserMixin';
import CountriesMixin from '@/mixins/CountriesMixin';

export default {
  name: 'account-form',
  props: {
    value: {
      type: Object,
    },
    apiErrors: {
      type: Object,
    },
    publishers: {
      type: Object,
    },
    collections: {
      type: Object,
    },
    accountFields: {
      type: Object,
      default: () => ({
        first_name: true,
        last_name: true,
        password: true,
        email: true,
        weekly_digest_optin: true,
        job_role: false,
        country: '',
        region: '',
        permissions: true,
      }),
    },
    countries: {
      type: Array,
    },
  },
  mixins: [FormMixin, UserMixin, CountriesMixin],
  components: { LoadingButton, ValidationObserver, ValidationProvider },
  data() {
    return {
      regions: [],
      changePassword: false,
    };
  },
  mounted() {
    this.updateRegions(this.mutableValue.country);
  },
  methods: {
    ucfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    handleChange(e) {
      const key = e.target.getAttribute('data-key');
      const type = e.target.getAttribute('data-type');
      const action = e.target.getAttribute('data-action');
      const { checked } = e.target;
      const currentPermissions = { ...this.value.permissions };
      const i = currentPermissions.books_access.findIndex((value) => (value.key === key && value.type === type));

      if (i >= 0) {
        currentPermissions.books_access[i][action] = checked;
        if (!currentPermissions.books_access[i].include_drafts && !currentPermissions.books_access[i].bypass_paywall) {
          currentPermissions.books_access.splice(i, 1);
        }
      } else {
        const newPermission = {
          key,
          type,
          include_drafts: false,
          bypass_paywall: false,
        };

        newPermission[action] = checked;
        currentPermissions.books_access.push(newPermission);
      }

      this.$set(this.value, 'permissions', currentPermissions);
    },
  },
  watch: {
    'mutableValue.country': function (countryCode) {
      if (!this.hasInitialRegion) {
        this.mutableValue.region = null;
      }
      this.hasInitialRegion = false;
      this.updateRegions(countryCode);
    },
    apiErrors() {
      if (this.apiErrors) {
        this.apiErrors.forEach((item) => {
          this.$validator.errors.add({
            field: item[0],
            msg: item[1][0],
          });
        });
      }
    },
  },
  computed: {
    mutableValue() {
      return Object.assign(this.value);
    },
  },
};
</script>
<style>
.permissions__title {
  margin-top: 7px;
  font-weight: bold;
}
</style>
